/**
 * Prins (prins-grundsystem-2023)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author heiko
 * @version v2.230727 (2023/07/27 12:39:47, menu.js)
 */

import {
	debounce,
	executeAfterTransition,
	getUid,
	normalizeAttributeValue,
	triggerReflow
} from '../../../shared/utils';
import {
	scrollElementIntoView,
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/utils/scroll';
import {getTabbableBoundary} from "../../../shared/utils/tabbable";

import SelectorEngine from '../../../shared/dom/selector-engine';
import Manipulator from '../../../shared/dom/manipulator';
import EventHandler from '../../../shared/dom/event-handler';

import Nav from '../../../shared/components/nav/nav';

// -------
// Private
// -------

const NAME      = 'menu';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const appMenu       = SelectorEngine.findOne('#app-menu');
const appMenuHandle = SelectorEngine.findOne('[aria-controls="app-menu"]');

let appMenuOverlay, appMenuBody;

/**
 * Erkennen/festlegen welches Menülayout angezeigt wird.
 */
const checkMenuLayout = () => {
	const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	if (screenWidth < window.Prins.config.breakpointMenuCanvas) {
		Manipulator.setDataAttribute(document.documentElement, 'menu-layout', 'canvas');
	} else {
		Manipulator.setDataAttribute(document.documentElement, 'menu-layout', 'default');
	}
};

/**
 * Initialisierung der eigentlichen Navigationsliste.
 */
const navigation = () => {
	const nav = SelectorEngine.findOne('[data-nav="pages"]');

	if (nav) {
		window.Prins.navInstance = new Nav(nav, {
			closeByOutsideClick: false,
			closeByEsc         : false,
			labelSubmenu       : (window.Prins.tm) ? window.Prins.tm.translate('ariaLabelSubmenu') : 'Submenu for %s',
			labelSubmenuTrigger: (window.Prins.tm) ? window.Prins.tm.translate('ariaLabelSubmenuTrigger') : 'Show submenu for %s',
			useReveal          : {
				visibleDisplay: 'flex'
			},
			onHide             : () => {
				// Manipulator.addClass(event.relatedMenu, '-will-hide');
			},
			onHidden           : () => {
				// Manipulator.removeClass(event.relatedMenu, '-will-hide');
			},
			onShow             : () => {
				// Manipulator.addClass(event.relatedMenu, '-will-show');
			},
			onShown            : () => {
				// Manipulator.removeClass(event.relatedMenu, '-will-show');
			}
		});

		window.Prins.navInstance.showCurrentTree();

		const curPageItem = SelectorEngine.findOne('[aria-current="page"]');

		if (appMenuBody && curPageItem) {
			scrollElementIntoView(curPageItem, appMenuBody, 'vertical');
		}
	}
};

/**
 * Funktion des Menütriggers bei Klick.
 *
 * @param event
 */
const handleAppMenu = (event) => {
	// const curMenuLayout   = Manipulator.getDataAttribute(document.documentElement, 'menu-layout');
	// const menuIsMinimized = Manipulator.getDataAttribute(document.documentElement, 'menu-minimized');
	const n = (event.delegateTarget.classList.contains('_active')) ? 'hide' : 'show';

	EventHandler.trigger(appMenu, `${n}${EVENT_KEY}`);
};

/**
 * Focus wieder auf den Menütrigger zurücksetzen.
 */
const setFocusBackToHandle = function() {
	if (appMenuHandle && typeof appMenuHandle.focus === 'function') {
		setTimeout(() => appMenuHandle.focus());
	}
};

/**
 * Menü auf Canvas-Layout umschalten.
 */
const appMenuCanvas = () => {
	//
	// Vorherige, vom Default-Typ, ev. gesetzte Attribute und Events entfernen!
	//

	EventHandler.off(appMenu, `hide${EVENT_KEY}`);
	EventHandler.off(appMenu, `show${EVENT_KEY}`);

	Manipulator.removeDataAttribute(document.documentElement, 'menu-minimized');

	//
	// Menüanpassungen.
	//

	// Overlay integrieren, wenn es noch nicht existiert.
	if (!appMenuOverlay) {
		appMenuOverlay = Manipulator.elementAfter('<div aria-hidden="true" class="app-menu-overlay" hidden tabindex="-1"/>', appMenu);

		// Event ´show´.
		EventHandler.on(appMenuOverlay, `show${EVENT_KEY}`, (event) => {
			event.target.hidden = false;

			triggerReflow(event.target);

			Manipulator.addClass(event.target, '-show');
			Manipulator.removeClass(event.target, '-hide');
		});

		// Event ´hide´.
		EventHandler.on(appMenuOverlay, `hide${EVENT_KEY}`, (event) => {
			Manipulator.addClass(event.target, '-hide');
			Manipulator.removeClass(event.target, '-show');

			executeAfterTransition(
				() => {
					event.target.hidden = true;
				},
				event.target
			);
		});

		// Event ´click´, Menü etc. schließen.
		EventHandler.on(appMenuOverlay, `click${EVENT_KEY}`, () => {
			EventHandler.trigger(appMenu, `hide${EVENT_KEY}`);
		});
	}

	// Attribute des Menüs setzen.
	appMenu.tabindex = 0;
	appMenu.setAttribute('role', 'dialog');

	appMenuBody.tabindex = 0;

	Manipulator.setAria(appMenu, 'modal', 'true');

	// Events anbinden.
	// ... Event ´show´.
	EventHandler.on(appMenu, `show${EVENT_KEY}`, (event) => {
		if (!(event.target.isTransitioning || event.target.isOpen)) {
			EventHandler.trigger(appMenuOverlay, `show${EVENT_KEY}`);

			// Wenn der Container angezeigt wird, versucht Safari automatisch auf das erste Element mit dem Attribut
			// `autofocus` den Focus zu setzen. Dies kann zu unschönen visuellen Erscheinungen führen. Von daher wird
			// `autofocus` entfernt und nach dem Öffnen des Drawer wieder reintegriert.
			const elementAutoFocus = SelectorEngine.findOne('[autofocus]', event.target);

			if (elementAutoFocus) {
				elementAutoFocus.removeAttribute('autofocus');
			}

			// Scrollen des Dokumentes sperren.
			lockBodyScrolling(event.target);

			event.target.isTransitioning = true;

			triggerReflow(event.target);

			// Manipulator.setAria(event.target, 'hidden', 'false');
			Manipulator.addClass(event.target, '-show');
			Manipulator.removeClass(event.target, '-hide');

			executeAfterTransition(
				() => {
					event.target.isTransitioning = false;
					event.target.isOpen          = true;

					// Attribute `autofocus` reintegrieren.
					if (elementAutoFocus) {
						elementAutoFocus.setAttribute('autofocus', '');
					}

					const {start} = getTabbableBoundary(appMenuBody);

					if (start && typeof start.focus === 'function') {
						start.focus({preventScroll: true});
					} else {
						appMenuBody.focus({preventScroll: true});
					}

					if (appMenuHandle) {
						Manipulator.addClass(appMenuHandle, '_active');
					}
				},
				event.target
			);
		}
	});
	// ... Event ´hide´.
	EventHandler.on(appMenu, `hide${EVENT_KEY}`, (event) => {
		if (!event.target.isTransitioning && event.target.isOpen) {
			EventHandler.trigger(appMenuOverlay, `hide${EVENT_KEY}`);

			event.target.isTransitioning = true;

			// Manipulator.setAria(appMenu, 'hidden' , 'true');
			Manipulator.addClass(event.target, '-hide');
			Manipulator.removeClass(event.target, '-show');

			executeAfterTransition(
				() => {
					event.target.isTransitioning = false;
					event.target.isOpen          = false;

					// Scrollen des Dokumentes entsperren.
					unlockBodyScrolling(event.target);

					setFocusBackToHandle();

					if (appMenuHandle) {

						Manipulator.removeClass(appMenuHandle, '_active');
					}
				},
				event.target
			);
		}
	});
	// ... Event ´keyup´ (schließen per ESC).
	EventHandler.on(appMenu, `keyup${EVENT_KEY}`, (event) => {
		const key = event.key || event.keyCode;

		if (key === 'Escape' || key === 'esc' || key === 27) {
			EventHandler.trigger(appMenu, `hide${EVENT_KEY}`);
		}
	});

	// Menü ausblenden.
	EventHandler.trigger(appMenu, `hide${EVENT_KEY}`);
};

/**
 * Menü auf Standard-Layout umschalten.
 */
const appMenuDefault = () => {
	//
	// Vorherige, vom Canvas-Typ, ev. gesetzte Attribute und Events entfernen!
	//

	appMenu.removeAttribute('tabindex');
	appMenu.removeAttribute('role');

	Manipulator.removeAria(appMenu, 'modal');

	EventHandler.off(appMenu, `hide${EVENT_KEY}`);
	EventHandler.off(appMenu, `show${EVENT_KEY}`);
	EventHandler.off(appMenu, `keyup${EVENT_KEY}`);

	if (appMenuOverlay) {
		appMenuOverlay.remove();
	}

	//
	// Menüanpassungen.
	//

	// Bei Layout ´default´ ist ´hide´ = Menü nicht minimiert!
	EventHandler.on(appMenu, `hide${EVENT_KEY}`, () => {
		Manipulator.setDataAttribute(document.documentElement, 'menu-minimized', 'false');
		localStorage.setItem(`${window.Prins.prefix}menu-minimized`, 'false');

		if (appMenuHandle) {
			Manipulator.removeClass(appMenuHandle, '_active');
		}
	});

	// Bei Layout ´default´ ist ´show´ = Menü minimiert!
	EventHandler.on(appMenu, `show${EVENT_KEY}`, () => {
		Manipulator.setDataAttribute(document.documentElement, 'menu-minimized', 'true');
		localStorage.setItem(`${window.Prins.prefix}menu-minimized`, 'true');

		if (appMenuHandle) {
			Manipulator.addClass(appMenuHandle, '_active');
		}
	});

	// Wurde das Menü schon einmal minimiert, aktueller Status wird im LocalStorage hinterlegt, dann stelle diesen
	// Status wieder her.
	const menuMinimized = normalizeAttributeValue(localStorage.getItem(`${window.Prins.prefix}menu-minimized`));

	if (menuMinimized) {
		EventHandler.trigger(appMenu, `show${EVENT_KEY}`);
	} else {
		EventHandler.trigger(appMenu, `hide${EVENT_KEY}`);
	}
};

// -------
// Public
// -------

const menu = () => {
	if (!appMenu) {
		return;
	}

	// Elementreferenz wird benötigt um das Menü zu einem schon aktiven Menüpunkt zu scrollen.
	appMenuBody = SelectorEngine.findOne('.app-menu__body', appMenu);

	// Änderungen bzgl. des Menülayouts erfolgen per Data-Attribute auf dem Element ´document.documentElement´.
	// Dieses Element wird global überwacht und triggert bei erkennter Änderung ein ev. angebundenes Event
	// ´ifab.prins.documentMutation´.
	EventHandler.on(document.documentElement, 'ifab.prins.documentMutation', (event) => {
		for (const mutation of event.mutations) {
			if (mutation.type === 'attributes' && mutation.attributeName === 'data-menu-layout') {
				if (Manipulator.getDataAttribute(mutation.target, 'menu-layout') === 'canvas') {
					appMenuCanvas();
				} else {
					appMenuDefault();
				}
			}
		}
	});

	// Menütrigger
	if (appMenuHandle) {
		if (!appMenuHandle.id) {
			appMenuHandle.setAttribute('id', getUid('app-menu-trigger'));
		}

		Manipulator.setAria(appMenu, 'labelledby' , appMenuHandle.id);

		EventHandler.on(appMenuHandle, `click${EVENT_KEY}`, handleAppMenu);
	}

	// Menütyp bestimmen/erkennen & überwachen.
	checkMenuLayout();
	window.addEventListener('resize', debounce(checkMenuLayout));

	// Navigationsliste initialisieren.
	navigation();
};

// Export
export default menu;
