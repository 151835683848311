/**
 * Prins: Components / Form (modify Shared)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../../../../shared/utils/index';

import SelectorEngine from '../../../../../shared/dom/selector-engine';
import EventHandler from '../../../../../shared/dom/event-handler';
import Manipulator from '../../../../../shared/dom/manipulator';
// import Data from "../../../../../shared/dom/data";

import CheckGroupRequired from '../../../../../shared/components/form/tools/check-group-required';
import ClearableField from '../../../../../shared/components/form/tools/clearable-field';
import ToggleReadOnly from '../../../../../shared/components/form/tools/toggle-readonly';
import ToggleFormSection from '../../../../../shared/components/form/tools/toggle-form-section';
import NativeFormValidation from '../../../../../shared/components/form/tools/native-form-validation';
import AutoValue from './tools/auto-value';

// -------
// Private
// -------

/**
 * Verhindere Browserhistorie und Navigation, wenn es geänderte ungespeicherte Formular gibt.
 */
const setupDetectFormChanges = () => {
	// Formulare mit Überwachung Datenänderung
	const forms = SelectorEngine.find('form[data-detect-changes]');

	const handleElements4Enable = (form, flag) => {
		const formKey      = form.dataset.formKey ?? null;
		const elements     = SelectorEngine.find('[data-enable-on-changes]', form);
		const customSubmit = (formKey) ? SelectorEngine.find(`[data-form-submit="${formKey}"]`) : [];

		if (elements.length > 0) {
			for (const element of elements) {
				Manipulator.setDisabled(element, !flag);
			}
		}

		if (customSubmit.length > 0) {
			for (const element of customSubmit) {
				Manipulator.setDisabled(element, !flag);
			}
		}
	};

	if(forms.length) {
		// Flag global in `window.Prins` bereitstellen.
		window.Prins.formChangeDetected = window.Prins.formChangeDetected || null;

		// Window event ´beforeunload´.
		window.addEventListener('beforeunload', event => {
			// Wurde globale Flag gesetzet?
			if (window.Prins.formChangeDetected) {
				event.preventDefault();

				event.returnValue = '';
			}
		});

		for (const form of forms) {
			handleElements4Enable(form, false);

			EventHandler.on(form, 'submit.ifab', () => {
				window.Prins.formChangeDetected = false;
			});

			// Eigenes Form-Event anbinden, da bestimmte Custom-JS-Parts nicht vom Standard-Input-Event eines Formulares
			// beachtet werden können. In `event.changeDetectedOn` befindet sich die jeweilige Elmeentreferenz.
			EventHandler.on(form, 'changeDetected', () => {
				// Globales Flag setzen.
				window.Prins.formChangeDetected = form;

				handleElements4Enable(form, true);
			});

			// Standard-Input-Event an das Formular anbinden und betroffenes Element übergeben.
			form.addEventListener('input', function (event) {
				EventHandler.trigger(form, 'changeDetected', {
					changeDetectedOn : event.target
				});
			});
		}
	}
};

/**
 * Automatisches Absenden des Eleternformulares bei Änderungen eines Formularelementes.
 * @param {Object} o
 * @returns Array
 */
const setupAutoSubmit = (o = {}) => {
	const _o         = extend({}, {
		timeout : 100
	}, o);
	const collection = SelectorEngine.find('[data-form-autosubmit]');

	if (collection.length) {
		for (const element of collection) {
			const form = SelectorEngine.parents(element, '.form');
			const type = element.getAttribute('type');

			let evName = (element.tagName.toLowerCase() === 'select') ? 'change' : '';

			if (!evName && type && ['radio', 'checkbox'].includes(type)) {
				evName = 'click';
			}

			if (evName && form[0]) {
				EventHandler.on(element, evName, () => {
					setTimeout(() => {
						HTMLFormElement.prototype.submit.call(form[0]);
						// form[0].submit();
					}, _o.timeout);
				});
			}
		}
	}

	return collection;
};

// TODO (JS), ´customFormSubmit´ kollidiert mit NativeFormValidation
const customFormSubmit = () => {
	/*const forms = SelectorEngine.find('form[data-form-key]');

	if (forms.length > 0) {
		for (const form of forms) {
			const key     = form.dataset.formKey;
			const buttons = (key) ? SelectorEngine.find(`[data-form-submit="${key}"]`) : null;

			if (buttons.length > 0) {
				for (const button of buttons) {
					EventHandler.on(button, 'click', (event) => {
						event.preventDefault();

						EventHandler.trigger(form, 'submit.ifab');
					});
				}
			}
		}
	}*/
};

// ------
// Public
// ------

/**
 * Initialisierung.
 *
 * @param {Object} o
 * @returns Array
 */
const init = (o = {}) => {
	// Checkbox-, Radiogruppen (multiple & required) initialisieren.
	const collectionCG = SelectorEngine.find('[data-check-group-required]');

	if (collectionCG.length) {
		for (const group of collectionCG) {
			new CheckGroupRequired(group);
		}
	}

	// Clearable fields initialisieren.
	const collectionCF = SelectorEngine.find('input[data-clearable]:not([data-provider="autocomplete"])');

	if (collectionCF.length) {
		for (const element of collectionCF) {
			new ClearableField(element);
		}
	}

	// Toggle read-only fields initialisieren.
	const collectionTROF = SelectorEngine.find('[data-toggle-readonly]');

	if (collectionTROF.length) {
		for (const element of collectionTROF) {
			new ToggleReadOnly(element);
		}
	}

	// Toggle form section initialisieren.
	const collectionTFS = SelectorEngine.find('[data-toggle-form-section]');

	if (collectionTFS.length) {
		for (const element of collectionTFS) {
			new ToggleFormSection(element);
		}
	}

	// Formulare ´native validation´ initialisieren.
	const collectionFNV = SelectorEngine.find('form[novalidate]');

	if (collectionFNV.length) {
		for (const element of collectionFNV) {
			new NativeFormValidation(element, {
				onValidationFailed  : (event) => {
					if (event.fieldsets.length) {
						for (const fieldset of event.fieldsets) {
							Manipulator.addClass(fieldset, '-validation-failed');
						}
					}

					if (event.fields.length) {
						for (const field of event.fields) {
							Manipulator.addClass(field, '-validation-failed');
						}
					}
				}
			});
		}
	}

	// Formularfelder ´auto value´ initialisieren.
	const collectionAV = SelectorEngine.find('[data-form-autovalue]');

	if (collectionAV.length) {
		for (const element of collectionAV) {
			new AutoValue(element);
		}
	}

	setupAutoSubmit();
	setupDetectFormChanges();
	customFormSubmit();
};

// Export
export default {
	init: init
};
