/**
 * Prins: Components / Drawer (modify Shared)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {getElementFromSelector} from '../../../../../shared/utils/index';

import SelectorEngine from '../../../../../shared/dom/selector-engine';
import EventHandler from '../../../../../shared/dom/event-handler';
import Manipulator from '../../../../../shared/dom/manipulator';
import Drawer from '../../../../../shared/components/drawer/drawer';

// -------
// Private
// -------

// ------
// Public
// ------

/**
 * Initialisierung.
 */
const init = () => {
	const toggles = SelectorEngine.find('[data-toggle-drawer]');

	if (toggles.length) {
		for (const toggle of toggles) {
			const target = getElementFromSelector(toggle);

			if (target) {
				const drawer = new Drawer(target, {
					onShow: (event) => {
						if (event.trigger) {
							Manipulator.addClass(event.trigger, '_active');
						}
					},
					onHidden: (event) => {
						if (event.trigger) {
							Manipulator.removeClass(event.trigger, '_active');
						}
					}
				});

				EventHandler.on(toggle, 'click.ifab.drawer', (event) => {
					event.preventDefault();

					drawer.toggle();
				});
			}
		}
	}
};

// Export
export default {
	init: init
};
