/**
 * Prins: Components / Card (modify Shared)
 *
 * @copyright 2024 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../../../../shared/utils';
import {
	isElement,
	isString
} from '../../../../../shared/utils/is';

import SelectorEngine from '../../../../../shared/dom/selector-engine';
import Data           from '../../../../../shared/dom/data';
import Manipulator    from '../../../../../shared/dom/manipulator';

import Spinner from '../../../../../shared/components/spinner/spinner';

// -------
// Private
// -------

const NAME     = 'card';
const DATA_KEY = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const DEFAULTS = {
	container: null
};

const handleLoading = (element) => {
	const classBackdrop = 'loading-backdrop';
	// const _o = Data.get(element, `${DATA_KEY}.options`) || DEFAULTS;

	if (Manipulator.hasDataAttribute(element, 'loading')) {
		Manipulator.elementAppend(`<span class="${classBackdrop}"></span>`, element);

		Spinner.addTo(element);
	} else {
		const backdrop = SelectorEngine.findOne(`.${classBackdrop}`, element);

		if (backdrop) {
			backdrop.remove();
		}

		Spinner.removeFrom(element);
	}
};

/**
 * Monitor card changes.
 *
 * @type {MutationObserver}
 */
const observer = new MutationObserver((mutations) => {
	for (const mutation of mutations) {
		if (mutation.type === 'attributes') {
			switch (mutation.attributeName) {
				case 'data-loading': {
					handleLoading(mutation.target);
					break;
				}
			}
		}
	}
});

/**
 * ´Button´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	Data.set(element, `${DATA_KEY}.options`, o);

	handleLoading(element);

	observer.observe(element, {
		attributes: true
	});

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// ------
// Public
// ------

/**
 * ´Card´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `.${NAME}`,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(render(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init: init
};
