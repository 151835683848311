/**
 * Prins: Components / Modal
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {getSelector} from "../../../../shared/utils";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'modal';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const getControlUri = (control) => {
	const tag = control.tagName.toLowerCase();

	// Hole URI aus dem Attribut ´data-uri´.
	let uri = control.dataset.uri;

	// Ist `control` ein Link und `uri` ist leer, dann hole URI aus dem Attribut `href`.
	if (tag === 'a' && control.getAttribute('href') && !uri) {
		// URI speichern.
		uri = control.getAttribute('href');

		// Attribut `href` auf ´leer´ setzen.
		control.setAttribute('href', '#');
	}

	// Attribut ´data-uri´ entfernen.
	delete control.dataset.uri;

	return uri;
};

const copyContents = (source, target) => {
	const sourceNodes = SelectorEngine.children(source, '*');
	const sourceText  = (sourceNodes.length <= 0) ? source.textContent : '';

	if (sourceNodes.length > 0 || sourceText) {
		if (sourceNodes.length > 0) {
			for (const sourceNode of sourceNodes) {
				target.append(sourceNode.cloneNode(true));
				// Manipulator.elementAppend(sourceNode, target);
			}
		} else {
			target.textContent = sourceText;
		}
	}
};

const modalDelete = () => {
	const container = SelectorEngine.findOne('#modal-delete');
	const controls  = SelectorEngine.find('[data-trigger-modal="delete"]');

	if (container) {
		if (!window.Prins.modalDelete) {
			const modal = new bootstrap.Modal(container, {
				backdrop: 'static',
				keyboard: false
			});

			const msg     = SelectorEngine.findOne('[data-modal-msg]', container);
			const cancel  = SelectorEngine.findOne('button[data-modal-control="cancel"]', container);
			const confirm = SelectorEngine.findOne('button[data-modal-control="confirm"]', container);

			// Modal wird geöffnet, Datensatzname eintragen und Events aktivieren.
			container.addEventListener('show.bs.modal', (event) => {
				const trigger = event.relatedTarget;

				if (trigger) {
					const uri = Data.get(trigger, `${DATA_KEY}.uri`);

					msg.textContent = trigger.dataset.modalText || '';

					Data.set(event.target, `${DATA_KEY}.currentTrigger`, trigger);
					Manipulator.addClass(trigger, '_active');

					if (uri) {
						Manipulator.setDisabled(cancel, false);
						Manipulator.setDisabled(confirm, false);

						EventHandler.on(confirm, `click${EVENT_KEY}`, (event) => {
							event.preventDefault();

							confirm.dataset.loading = 'busy';

							Manipulator.setDisabled(cancel, true);

							window.location.replace(uri);
						});
					} else {
						Manipulator.setDisabled(confirm, true);
					}
				}
			});

			// Modal wird geschlossen > Confim deaktivieren.
			container.addEventListener('hide.bs.modal', () => {
				Manipulator.setDisabled(confirm, true);
				EventHandler.off(confirm, `click${EVENT_KEY}`);
			});

			// Modal wurde geschlossen, Datensatzname entfernen und.
			container.addEventListener('hidden.bs.modal', (event) => {
				const trigger = Data.get(event.target, `${DATA_KEY}.currentTrigger`);

				if (trigger) {
					Manipulator.addClass(trigger, '_active');
				}

				msg.textContent = '';
			});

			window.Prins.modalDelete = modal;
		}

		for (const control of controls) {
			const uri = getControlUri(control);

			if (uri === '') {
				// Gibt es keine URI, dann deaktiviere das Element!
				Manipulator.setDisabled(control);
			} else {
				Data.set(control, `${DATA_KEY}.uri`, uri);

				EventHandler.on(control, `click${EVENT_KEY}`, (event) => {
					event.preventDefault();

					window.Prins.modalDelete.show(event.delegateTarget);
				});
			}
		}
	}
};

const modalInfo = () => {
	const container = SelectorEngine.findOne('#modal-info');
	const controls  = SelectorEngine.find('[data-trigger-modal="info"]');

	if (container) {
		if (!window.Prins.modalInfo) {
			const modal = new bootstrap.Modal(container);
			const msg   = SelectorEngine.findOne('[data-modal-msg]', container);
			const title = SelectorEngine.findOne('[data-modal-title]', container);

			// Modal wird geöffnet, Daten eintragen.
			container.addEventListener('show.bs.modal', (event) => {
				const trigger = event.relatedTarget;
				const target  = Data.get(trigger, `${DATA_KEY}.target`);

				Manipulator.addClass(container, Data.get(container, `${DATA_KEY}.modalClass`));
				Manipulator.addClass(trigger, '_active');

				copyContents(target, msg);

				title.textContent = (trigger.dataset.title) ?? trigger.getAttribute('title');

				Data.set(event.target, `${DATA_KEY}.currentTrigger`, trigger);
			});

			// Modal wurde geschlossen, Daten entfernen.
			container.addEventListener('hidden.bs.modal', (event) => {
				const trigger = Data.get(event.target, `${DATA_KEY}.currentTrigger`);

				title.textContent = '';
				msg.textContent   = '';

				Manipulator.removeClass(container, Data.get(container, `${DATA_KEY}.modalClass`));

				// Set focus back to trigger.
				if (trigger) {
					Manipulator.removeClass(trigger, '_active');

					setTimeout(() => trigger.focus());
				}
			});

			window.Prins.modalInfo = modal;
		}

		// Modal trigger holen und initialisieren.
		for (const control of controls) {
			const selector = getSelector(control);
			const target   = (selector) ? SelectorEngine.findOne(selector) : null;

			if (target) {
				Data.set(control, `${DATA_KEY}.target`, target);

				EventHandler.on(control, `click${EVENT_KEY}`, (event) => {
					event.preventDefault();

					Data.set(container, `${DATA_KEY}.modalClass`, (control.dataset.modalClass ?? ''));

					window.Prins.modalInfo.show(event.delegateTarget);
				});
			} else {
				Manipulator.setDisabled(control);
			}
		}
	}
};

const modalIframe = () => {
	const container = SelectorEngine.findOne('#modal-iframe');
	const controls  = SelectorEngine.find('[data-trigger-modal="iframe"]');

	if (container) {
		if (!window.Prins.modalIframe) {
			const modal     = new bootstrap.Modal(container);
			const modalBody = SelectorEngine.findOne('.modal-body', container);
			const msg       = SelectorEngine.findOne('[data-modal-msg]', container);
			const title     = SelectorEngine.findOne('[data-modal-title]', container);

			// Modal wird geöffnet, Daten eintragen.
			container.addEventListener('show.bs.modal', (event) => {
				const modalClass = Data.get(container, `${DATA_KEY}.modalClass`);
				const iframeScrolling = (modalClass.includes('-full-size') || modalClass.includes('-full-height')) ? 'yes' : 'no';
				const trigger    = event.relatedTarget;
				const uri        = Data.get(trigger, `${DATA_KEY}.uri`);
				const iframe     = Manipulator.elementPrepend(`<iframe src="${uri}" scrolling="${iframeScrolling}"></iframe>`, modalBody);

				Manipulator.addClass(container, modalClass);
				Data.set(container, `${DATA_KEY}.iframe`, iframe);

				Data.set(event.target, `${DATA_KEY}.currentTrigger`, trigger);
				Manipulator.addClass(trigger, '_active');

				if (title) {
					title.innerHTML = (trigger.dataset.title) ?? trigger.getAttribute('title');
				}

				if (msg) {
					msg.innerHTML = (trigger.dataset.msg) ?? '';
				}

				window.Prins.modalIframeCurrent = iframe;

				// iframe.height = iframe.contentWindow.document.body.scrollHeight;
			});

			// Modal wurde geschlossen, Daten entfernen.
			container.addEventListener('hidden.bs.modal', (event) => {
				const trigger = Data.get(event.target, `${DATA_KEY}.currentTrigger`);
				const iframe  = Data.get(container, `${DATA_KEY}.iframe`);

				Manipulator.removeClass(container, Data.get(container, `${DATA_KEY}.modalClass`));

				if (title) {
					title.textContent = '';
				}
				if (msg) {
					msg.textContent = '';
				}

				iframe.remove();

				window.Prins.modalIframeCurrent = null;

				// Set focus back to trigger.
				if (trigger) {
					Manipulator.removeClass(trigger, '_active');

					setTimeout(() => trigger.focus());
				}
			});

			window.Prins.modalIframe = modal;
		}

		// Modal trigger holen und initialisieren.
		for (const control of controls) {
			const uri = getControlUri(control);

			if (uri) {
				Data.set(control, `${DATA_KEY}.uri`, uri);

				EventHandler.on(control, `click${EVENT_KEY}`, (event) => {
					event.preventDefault();

					Data.set(container, `${DATA_KEY}.modalClass`, (control.dataset.modalClass ?? ''));

					window.Prins.modalIframe.show(event.delegateTarget);
				});
			} else {
				Manipulator.setDisabled(control);
			}
		}
	}
};

// Modals ohne sep. Trigger.

const modalError = () => {
	const container = SelectorEngine.findOne('#modal-error');

	if (container && !window.Prins.error) {
		const modal = new bootstrap.Modal(container, {
			backdrop: 'static',
			keyboard: false
		});

		const msg     = SelectorEngine.findOne('[data-modal-msg]', container);
		// const cancel  = SelectorEngine.findOne('button[data-modal-control="cancel"]', container);
		// const confirm = SelectorEngine.findOne('button[data-modal-control="confirm"]', container);

		// Modal wird geöffnet, Datensatzname eintragen und Events aktivieren.
		container.addEventListener('show.bs.modal', (event) => {
			msg.textContent = event.relatedTarget.msg || '';
		});

		// container.addEventListener('hide.bs.modal', (event) => {});

		// container.addEventListener('hidden.bs.modal', (event) => {});

		window.Prins.error = modal;
	}
};

const modalConfirm = () => {
	const container = SelectorEngine.findOne('#modal-confirm');
	const controls  = SelectorEngine.find('[data-trigger-modal="confirm"]');

	if (container) {
		if(!window.Prins.modalConfirm) {
			const modal = new bootstrap.Modal(container, {
				backdrop: 'static',
				keyboard: false
			});

			const msg     = SelectorEngine.findOne('[data-modal-msg]', container);
			const cancel  = SelectorEngine.findOne('button[data-modal-control="cancel"]', container);
			const confirm = SelectorEngine.findOne('button[data-modal-control="confirm"]', container);

			// Modal wurde geöffnet.
			container.addEventListener('show.bs.modal', (event) => {
				const trigger = event.relatedTarget;

				if (trigger) {
					const uri = Data.get(trigger, `${DATA_KEY}.uri`);

					msg.textContent = trigger.dataset.modalText || '';

					Data.set(event.target, `${DATA_KEY}.currentTrigger`, trigger);
					Manipulator.addClass(trigger, '_active');

					if (uri) {
						Manipulator.setDisabled(cancel, false);
						Manipulator.setDisabled(confirm, false);

						EventHandler.on(confirm, `click${EVENT_KEY}`, (event) => {
							event.preventDefault();

							confirm.dataset.loading = 'busy';

							Manipulator.setDisabled(cancel, true);

							window.location.replace(uri);
						});
					} else {
						Manipulator.setDisabled(confirm, true);
					}
				} else {
					Manipulator.setDisabled(cancel, false);
					Manipulator.setDisabled(confirm, false);

					EventHandler.on(confirm, 'click', (event) => {
						EventHandler.trigger(container, 'modalConfirm.confirm', {
							container
						});
					});

					EventHandler.on(cancel, 'click', (event) => {
						EventHandler.trigger(container, 'modalConfirm.cancel', {
							container
						});
					});

					EventHandler.trigger(container, 'modalConfirm.show', {
						container
					});
				}
			});

			// Modal wird geschlossen.
			container.addEventListener('hide.bs.modal', () => {
				Manipulator.setDisabled(cancel, true);
				Manipulator.setDisabled(confirm, true);

				EventHandler.trigger(container, 'modalConfirm.hide', {
					container
				});
			});

			// Modal wurde geschlossen.
			container.addEventListener('hidden.bs.modal', () => {
				EventHandler.trigger(container, modalConfirm.hidden, {
					container
				});

				EventHandler.off(container, 'modalConfirm.confirm');
				EventHandler.off(container, 'modalConfirm.cancel');
				EventHandler.off(container, 'modalConfirm.show');
				EventHandler.off(container, 'modalConfirm.hide');
				EventHandler.off(container, 'modalConfirm.hidden');
			});

			window.Prins.modalConfirm = modal;
		}

		for (const control of controls) {
			const uri = getControlUri(control);

			if (uri === '') {
				// Gibt es keine URI, dann deaktiviere das Element!
				Manipulator.setDisabled(control);
			} else {
				Data.set(control, `${DATA_KEY}.uri`, uri);

				EventHandler.on(control, `click${EVENT_KEY}`, (event) => {
					event.preventDefault();

					window.Prins.modalConfirm.show(event.delegateTarget);
				});
			}
		}
	}
};

// ------
// Public
// ------

/**
 * ´Modal´-Elemente initialisieren.
 *
 * @returns {HTMLElement|Array}
 */
const init = () => {
	//
	// Ist der aktuelle Kontext des Dokumentes ´modal´ (also ein Iframe), dann muss die aktuelle Höhe des Dokumentes an
	// das aktive Modal in `window.parent` übergeben werden.
	//

	if (Manipulator.getDataAttribute(document.documentElement, 'page-context') === 'modal') {
		const currentModal      = window.parent.Prins.modalIframeCurrent;
		const containerAppModal = SelectorEngine.findOne('.app-modal');

		if (currentModal) {
			const updateHeight = () => {
				setTimeout(() => {
					currentModal.style.setProperty(`--_modal-iframe-doc-scroll-height`, `${document.body.scrollHeight}px`);
				}, 50);
			};

			const modalResizeObserver = new ResizeObserver(entries => {
				if (entries[0].target) {
					updateHeight();
				}
			});

			modalResizeObserver.observe(containerAppModal);

			updateHeight();
		}
	}

	modalDelete();
	modalError();
	modalInfo();
	modalIframe();
	modalConfirm();
};

// Export
export default {
	init: init
};
