/**
 * Prins: Components / Upload picture preview
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	execute,
	executeAfterTransition,
	getUid,
	noop
} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator from '../../../../shared/dom/manipulator';
import EventHandler from '../../../../shared/dom/event-handler';

import BaseClass from '../../../../shared/utils/base-class';

import Spinner from '../../../../shared/components/spinner/spinner';

/**
 * @type {string}
 */
const NAME = 'upload-preview';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	removable     : false,
	preview       : {
		alt          : 'Preview',
		iconUndefined: '<i aria-hidden="true" class="fa-solid fa-file"></i>'
	},
	add           : {
		label: 'Select file',
		text : 'Select file',
		icon : '<i aria-hidden="true" class="fa-solid fa-upload"></i>'
	},
	remove        : {
		label: 'Remove file',
		text : 'Remove file',
		icon : '<i aria-hidden="true" class="fa-solid fa-trash"></i>'
	},
	onSelect      : noop,
	onRemove      : noop,
	onNoFileReader: noop
};

/**
 *  Class
 */
class UploadPreview extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = UploadPreview.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this._fieldUpload = SelectorEngine.findOne('input[type="file"]', this._element);
		this._fieldRemove = null;

		if (this._fieldUpload) {
			this._fieldRemove   = SelectorEngine.findOne('input[type="hidden"]', this._element);

			this._id           = this._fieldUpload.getAttribute('id') || getUid(`${this.constructor.NAME}`);
			this._allowedTypes = this._element.dataset.type ? this._element.dataset.type.split(',') : [];
			this._required     = this._fieldUpload.hasAttribute('required');
			this._isSet        = !(this._element.dataset.isSet === undefined || this._element.dataset.isSet === 'false');

			// Option ´removable´ aktualisieren.
			if (
				this._fieldRemove ||
				!(this._element.dataset.removable === undefined || this._element.dataset.removable === 'false')
			) {
				this._config.removable = true;
			}

			// Attribut ´data-removable´ entfernen.
			delete this._element.dataset.removable;

			this._element.dataset.loading = 'true';

			// Innerer Container.
			this._container = Manipulator.elementAppend(`<div class="upload-preview__container">`, this._element);

			// Loader
			this._loader = Manipulator.elementAppend(`<div aria-hidden="true" class="upload-preview-loader" tabindex="-1"/>`, this._container);
			Spinner.addTo(this._loader);

			this._render();
		}
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_render() {
		this._fieldUpload.setAttribute('id', this._id);

		//
		// Vorschaucontainer
		//

		this._previewContainer = Manipulator.elementAppend(`<div class="upload-preview-display"/>`, this._container);
		this._preview          = Manipulator.elementAppend(`<img alt="${this._config.preview.alt}" src="${this._element.dataset.preview ?? ''}"/>`, this._previewContainer);

		if(this._config.preview.iconUndefined) {
			Manipulator.elementAppend(this._config.preview.iconUndefined, this._previewContainer);
		}

		delete this._element.dataset.preview;

		//
		// Buttons
		//

		// Hinzufügen.
		this._add = Manipulator.elementAppend(`<button aria-controls="${this._id}" aria-label="${this._config.add.label}" data-label="${this._config.add.text}" class="button upload-preview-add" type="button">
			<span class="button__label">${this._config.add.text}</span>
		</button> `, this._container);

		if (this._config.add.icon) {
			Manipulator.elementPrepend(`<span class="button__prefix">${this._config.add.icon}</span>`, this._add);
		}

		// Entfernen.
		if (this._config.removable) {
			// Buttonelement
			this._remove = Manipulator.elementAppend(`<button aria-label="${this._config.remove.label}" class="button upload-preview-remove" type="button">
				<span class="button__label">${this._config.remove.text}</span>
			</button> `, this._container);

			// ... Icon integrieren?
			if (this._config.remove.icon) {
				Manipulator.elementPrepend(`<span class="button__prefix">${this._config.remove.icon}</span>`, this._remove);
			}

			// ... standardmäßig deaktivieren?
			if (this._fieldUpload.dataset.value === undefined || this._fieldUpload.dataset.value === '') {
				Manipulator.setDisabled(this._remove, true);
			}

			if (this._fieldRemove) {
				// Aria-Controls zu Hiddenfeld.
				this._fieldRemove.setAttribute('id', `${this._id}-remove`);
				this._remove.setAttribute('aria-controls', `${this._id}-remove`);
			} else {
				// Aria-Controls zu Uploadfeld.
				this._remove.setAttribute('aria-controls', this._id);
			}
		}

		//
		// Listeners anbinden, Loader aktivieren
		//

		this._addListeners();

		this._setLoading(false);
	}

	_addListeners() {
		if (window.File && window.FileReader && window.FileList && window.Blob) {
			this.fileReader = new FileReader();

			this.fileReader.addEventListener('load', () => {
				this._update();
			}, false);
		} else {
			this.fileReader = null;
		}

		//
		// File input
		//

		EventHandler.on(this._fieldUpload, `change${this.constructor.EVENT_KEY}`, (event) => {
			const file = event.target.files[0];
			const imageType = /image.*/;

			this._setLoading();

			if (imageType.test(file.type)) {
				if (this.fileReader) {
					this.fileReader.readAsDataURL(file);
				} else {
					this._update();
				}
			} else {
				// event.target.value = '';
				this._update();
			}
		});

		if (this._required) {
			this.uploadObserver = new MutationObserver((mutations) => {
				for (const mutation of mutations) {
					if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
						if (this._fieldUpload.classList.contains('-validation-failed')) {
							Manipulator.addClass(this._element, 'is-invalid');
						} else {
							Manipulator.removeClass(this._element, 'is-invalid');
						}
					}
				}
			});

			this.uploadObserver.observe(this._fieldUpload, {
				attributes: true
			});
		}

		//
		// Control ´add´ (triggert `this._fieldUpload`)
		//

		EventHandler.on(this._add, `click${this.constructor.EVENT_KEY}`, (event) => {
			event.preventDefault();

			this._fieldUpload.click();
		});

		EventHandler.on(this._add, `update${this.constructor.EVENT_KEY}`, (event) => {
			const label = SelectorEngine.findOne('.button__label', event.target);

			if (label) {
				label.textContent = (event.text && event.text !== '') ? event.text : event.target.dataset.label;
			}
		});

		EventHandler.trigger(this._add, `update${this.constructor.EVENT_KEY}`, {
			'text': this._getFileName(this._fieldUpload.dataset.value ?? '')
		});

		//
		// Control ´remove´ (triggert `this._element`)
		//

		if (this._remove) {
			EventHandler.on(this._remove, `click${this.constructor.EVENT_KEY}`, (event) => {
				event.preventDefault();

				this._setLoading();
				this._removeFile();
			});
		}
	}

	_update() {
		const src = (this.fileReader && this.fileReader.result) ? this.fileReader.result : '';

		this._preview.setAttribute('src', src);

		if (this._fieldRemove) {
			this._fieldRemove.value = 0;
		}

		Manipulator.removeClass(this._element, 'is-invalid');

		const fileName = this._getFileName(this._fieldUpload.value);

		EventHandler.trigger(this._add, `update${this.constructor.EVENT_KEY}`, {
			'text': fileName
		});

		this._fieldUpload.dataset.value = fileName;

		// Entfernenbutton aktivieren.
		if (this._config.removable) {
			Manipulator.setDisabled(this._remove, false);
		}

		execute(
			this._config.onSelect,
			{
				element    : this._element,
				fieldUpload: this._fieldUpload,
				fieldRemove: this._fieldRemove
			}
		);

		this._setLoading(false);
	}

	_removeFile() {
		this._fieldUpload.value = '';
		this._fieldUpload.dataset.value = '';

		this._preview.setAttribute('src', '');

		if (this._fieldRemove) {
			this._fieldRemove.value = 1;
		}

		// Entfernenbutton deaktivieren.
		if (this._config.removable) {
			Manipulator.setDisabled(this._remove, true);
		}

		if (this._required) {
			Manipulator.addClass(this._element, 'is-invalid');
		}

		EventHandler.trigger(this._add, `update${this.constructor.EVENT_KEY}`, {
			'text': ''
		});

		execute(
			this._config.onRemove,
			{
				element    : this._element,
				fieldUpload: this._fieldUpload,
				fieldRemove: this._fieldRemove
			}
		);

		this._setLoading(false);
	}

	_setLoading(flag = true) {
		if (flag) {
			this._loader.hidden = false;

			this._element.dataset.loading = 'true';
		} else {
			delete this._element.dataset.loading;

			executeAfterTransition(() => {
				this._loader.hidden = true;
			}, this._loader);
		}
	}

	_getFileName(path = '') {
		let s = path.replace(/\\/g, '/');

		return s.slice(Math.max(0, s.lastIndexOf('/') + 1));
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default UploadPreview;
