/**
 * Prins: Layout
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import header from './header/header';
import menu from './menu/menu';
import module from './main/module/module';

// ------
// Public
// ------

const layout = () => {
	header();
	menu();
	module();
};

// Export
export default layout;
