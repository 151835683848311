/**
 * Prins: Initial
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {normalizeAttributeValue, setRootVar} from '../../shared/utils/index';
import {focusVisible} from '../../shared/utils/focus-visible';
import {scrollTop} from '../../shared/utils/scroll';
import {loadJavascript} from "../../shared/utils/load";
import lazyLoad from '../../shared/utils/lazy-load';

import SelectorEngine from '../../shared/dom/selector-engine';
import Manipulator from '../../shared/dom/manipulator';
import EventHandler from '../../shared/dom/event-handler';

/**
 * Monitor ´document.documentElement´ changes.
 *
 * @type {MutationObserver}
 */
const observerDocumentMutation = new MutationObserver((mutations) => {
	EventHandler.trigger(document.documentElement, 'ifab.prins.documentMutation', {
		mutations
	});
});

const observerDocHeight = () => {
	const calc = () => {
		setRootVar(`${window.Prins.prefix}vh`, `${window.innerHeight/100}px`);
	};

	window.addEventListener('resize', calc);
	window.addEventListener('orientationchange', calc);

	calc();
};

const colorSchemeToggles  = SelectorEngine.find('[data-toggle="color-scheme"]');

const switchColorScheme = (scheme) => {
	document.documentElement.dataset.colorScheme = scheme;
	localStorage.setItem(`${window.Prins.prefix}color-scheme`, scheme);

	for (const toggle of colorSchemeToggles) {
		Manipulator.setAria(toggle, 'pressed',  (scheme === 'dark') ? 'true' : 'false');
	}
};

const colorScheme = () => {
	const colorSchemeBrowser = (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) ? 'dark' : 'light';
	const colorScheme        = normalizeAttributeValue(localStorage.getItem(`${window.Prins.prefix}color-scheme`)) ?? colorSchemeBrowser;

	for (const toggle of colorSchemeToggles) {
		Manipulator.setAria(toggle, 'pressed', 'false');

		EventHandler.on(toggle, 'click', (event) => {
			event.preventDefault();

			switchColorScheme(document.documentElement.dataset.colorScheme === 'dark' ? 'light' : 'dark');
		});
	}

	switchColorScheme(colorScheme);

	window
		.matchMedia('(prefers-color-scheme: dark)')
		.addEventListener('change', ({matches}) => {
			if (matches) {
				switchColorScheme('dark');
			} else {
				switchColorScheme('light');
			}
		});
};

/**
 * Accessibility (`:focus` erst bei Tastaturnavigation anzeigen.)
 */
const handleFocusableElements = () => {
	const collection = SelectorEngine.find('input, textarea, select, label, a, button');

	for (const element of collection) {
		focusVisible.observe(element);
	}
};

const bindLazyload = () => {
	// Fehlerbehandlung von ´fehlenden Bildern´ anbinden.
	const images = SelectorEngine.find('img');

	for (const image of images) {
		EventHandler.on(image, 'error', (event) => {
			Manipulator.addClass(event.target, 'is-faulty');
		});
	}

	// Lazyload initialisieren.
	lazyLoad(function() {
		const lazyElements = SelectorEngine.find('.lazyload[data-src]');

		if (lazyElements) {
			window.lazySizesConfig          = window.lazySizesConfig || {};
			window.lazySizesConfig.loadMode = 1;

			loadJavascript(`build/prins/vendors/lazysizes/lazysizes.min.js`, {
				async   : true,
				// callback: () => {},
				id      : 'prinsLazysizes'
			});
		}
	});
};

// -------
// Public
// -------

const initial = () => {
	observerDocumentMutation.observe(document.documentElement, {
		attributes: true
	});

	observerDocHeight();
	colorScheme();
	bindLazyload();
	handleFocusableElements();

	// ´History back´ - Elemente
	EventHandler.on(document.body, 'click', 'button[data-history-back]', (event) => {
		event.preventDefault();
		event.stopPropagation();

		history.back();
	});

	// ´Scroll top´ - Elwmente
	EventHandler.on(document, 'click', '[data-scroll-top]', (event) => {
		// preventDefault only for <a> elements (which change the URL) not inside the collapsible element
		if (
			event.target.tagName.toLowerCase() === 'a' ||
			(event.delegateTarget && event.delegateTarget.tagName.toLowerCase() === 'a')
		) {
			event.preventDefault();
		}

		scrollTop().then(element => {
			// `element` = zu ihm wird gescrollt
			// Do nothing ...
		});
	});

	// ...globalen ´Scroll top´-Button integrieren
	if (document.documentElement.dataset.pageContext && document.documentElement.dataset.pageContext !== 'modal') {
		const control = Manipulator.elementAppend(
			`<button aria-label="Back to top" class="icon-button" data-scroll-top="global">
				<span class="icon-button__icon">${window.Prins.config.icons.scrollTop}</span>
			</button>`,
			document.body
		);

		document.addEventListener('scroll', function () {
			requestAnimationFrame(() => {
				if (window.scrollY > window.innerHeight) {
					Manipulator.addClass(control, '-show');
				} else {
					Manipulator.removeClass(control, '-show');
				}
			});
		});
	}
};

// Export
export default initial;
