/**
 * Prins: Components / Custom > Choices
 * (Dependency: https://github.com/fabianlindfors/multi.js/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {
	extend,
	getSelector,
	normalizeAttributeValue
} from '../../../../shared/utils/index';
import {loadJavascript} from "../../../../shared/utils/load";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';
import Manipulator from '../../../../shared/dom/manipulator';

import Spinner from '../../../../shared/components/spinner/spinner';

// -------
// Private
// -------

const NAME      = 'choices';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const DEFAULTS = {
	allowHTML                : false,
	addItems                 : true,
	maxItemCount             : -1, // Anzahl an Einträgen die ausgewählt werden können (-1 = beliebig)
	noChoicesText            : '/', // No selection possible || Keine Auswahl möglich
	noResultsText            : '/', // Nothing found || Nichts gefunden
	paste                    : false,
	removeItemButton         : false,
	removeItems              : false,
	renderChoiceLimit        : -1, // Anzahl Einträge in Dropdown (-1 = beliebig)
	searchChoices            : true,
	searchEnabled            : false,
	searchFloor              : 1, // Min. Zeichen zum Suchen
	searchResultLimit        : 10,
	shouldSort               : false,
	callbackOnCreateTemplates: function (template) {
		return {
			item  : ({classNames}, data, removeButton) => {
				return template(`<div
					class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable} ${data.placeholder ? classNames.placeholder : ''}"
 					data-item
 					data-id="${data.id}"
 					data-value="${data.value}"
					${data.active ? ' aria-selected="true"' : ''}
					${data.disabled ? ' aria-disabled="true"' : ''}
				>
					<span class="${classNames.item}-label">${data.label}</span>
					${removeButton ? `<button aria-label="Remove ${data.value}" class="${classNames.button}" data-button type="button">Remove</button>`: ''}
				</div>`);
			},
			choice: ({classNames}, data) => {
				return template(`<div
					class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}"
					data-select-text="${this.config.itemSelectText}"
					data-choice
					${data.disabled ? ' data-choice-disabled aria-disabled="true"' : ' data-choice-selectable'}
					data-id="${data.id}"
					data-value="${data.value}"
					${data.groupId > 0 ? ' role="treeitem"' : ' role="option"'}
				>
					<span class="${classNames.item}-label">${data.label}</span>
				</div>`);
			}
		};
	}
};

const observerInView = new IntersectionObserver((entries) => {
	for (const element of entries) {
		if (element.isIntersecting) {
			render(element.target);
		}
	}
}, {
	root     : null,
	threshold: [0.25]
});

/**
 * Zusammenstellen der Parameter zum Element.
 *
 * @param {HTMLElement} element
  * @returns {Object}
 */
const determineOptions = (element) => {
	const params = {};

	// Aria-Labelled?
	const elId = element.getAttribute('id');
	const elLabel = (elId) ? SelectorEngine.findOne(`label[for="${elId}"]`) : null;

	if (elLabel) {
		elLabel.setAttribute('id', `label-${elId}`);

		params.labelId = elId;
	}

	// Einträge entfernbar?
	if (!(element.dataset.choicesRemovable === undefined)) {
		params.removeItems = true;
		params.removeItemButton = true;
	}

	// Durchsuchbar?
	if (!(element.dataset.choicesSearchable === undefined)) {
		params.searchEnabled = true;
	}

	return params;
};

/**
 * ´Select´ initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} [o={}]
 * @returns {HTMLElement}
 */
const render = (element, o = {}) => {
	// Wurde Container schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	//
	// Elementoptionen zusammenstellen, mergen und Referenzen speichern.
	//

	const elementParams = determineOptions(element);
	const _o            = extend({}, DEFAULTS, o, elementParams);

	// Elternformular wird für Erkennung einer Formularänderung benötigt.
	Data.set(element, `${DATA_KEY}.form`, (SelectorEngine.parents(element, 'form')[0] ?? null));

	//
	// Events
	//

	EventHandler.on(element, 'validationFailed.ifab', (event) => {
		const api = Data.get(event.target, `${DATA_KEY}.api`);

		if (api.containerOuter.element) {
			Manipulator.removeClass(api.containerOuter.element, '-validation-success');
			Manipulator.addClass(api.containerOuter.element, '-validation-failed');
		}
	});

	EventHandler.on(element, 'validationSuccess.ifab', (event) => {
		const api = Data.get(event.target, `${DATA_KEY}.api`);

		if (api.containerOuter.element) {
			Manipulator.removeClass(api.containerOuter.element, '-validation-failed');
			Manipulator.addClass(api.containerOuter.element, '-validation-success');
		}
	});

	EventHandler.on(element, 'change', (event) => {
		const form = Data.get(event.target, `${DATA_KEY}.form`);

		if (form) {
			EventHandler.trigger(form, 'changeDetected', {
				changeDetectedOn : event.target
			});
		}
	});

	//
	// Initialisierung
	//

	const api = new Choices(element, _o);

	// ... API im Element speichern.
	Data.set(element, `${DATA_KEY}.api`, api);

	// ... Status setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// ------
// Public
// ------

/**
 * ´Sort-, Filterlist´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`select[data-${NAME}], input[type="text"][data-${NAME}]`);

	if (collection.length) {
		if (window.Choices === undefined) {
			if (window.Prins.error) {
				window.Prins.error.show({
					msg: "The dependency ´Choices.js´ cannot be loaded/initialized"
				});
			} else {
				throw new Error('The dependency ´Choices.js´ cannot be loaded/initialized');
			}
		} else {
			for (const element of collection) {
				group.push(element);

				observerInView.observe(element);
			}
		}
	}

	return group;
};

// Export
export default {
	init: init
};
