/**
 * Prins: Components / Sortable
 * (Dependency: https://bevacqua.github.io/dragula/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {execute} from "../../../../shared/utils";
import {loadJavascript, loadStyle} from "../../../../shared/utils/load";

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Manipulator from '../../../../shared/dom/manipulator';
import Data from "../../../../shared/dom/data";

// -------
// Private
// -------

const NAME      = 'sortable';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const DEFAULTS = {};

const addSortableHandleTable = (container) => {
	const rows    = SelectorEngine.children(container, 'tr');
	const wrapper = Manipulator.createElementFrom(`<div class="sortable-handle-wrapper">
		<div class="sortable-handle-wrapper__handle">
			<button class="icon-button -default -soft -s sortable-handle" type="button" title="${window.Prins.tm.translate('sortRecord')}">
				<span class="icon-button__icon">${window.Prins.config.icons.moveVertical}</span>
			</button>
		</div>
	</div>`);
	const content = Manipulator.createElementFrom('<div class="sortable-handle-wrapper__original"></div>');

	for (const row of rows) {
		const firstCell    = SelectorEngine.findOne('th:first-child, td:first-child', row);
		const cloneWrapper = wrapper.cloneNode(true);
		const cloneContent = content.cloneNode(true);

		Manipulator.copyContent(firstCell, cloneContent);

		firstCell.innerHTML = '';

		Manipulator.elementAppend(cloneContent, cloneWrapper);
		Manipulator.elementAppend(cloneWrapper, firstCell);
		Manipulator.addClass(firstCell, 'sortable-handle-added');
	}
};

/**
 *
 * @param {HTMLElement} container
 * @returns {Array}
 */
const getCurrentSorting = (container) => {
	const childs = SelectorEngine.children(container, '*');

	let sorting = [];

	for (const child of childs) {
		const childId = child.dataset.sortId || child.dataset.id;

		if (childId) {
			sorting.push(childId);
		}
	}

	return sorting;
};

/**
 * ´Sortable connected´-Container initialisieren.
 *
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
const renderConnected = (element) => {};

/**
 * ´Sortable single´-Container initialisieren.
 *
 * @param {HTMLElement} container
 * @returns {HTMLElement}
 */
const render = (container) => {
	let useContainer = container;
	let childs       = SelectorEngine.children(useContainer, '*');

	const form         = SelectorEngine.parents(container, 'form')[0] ?? null;
	const xhr          = container.dataset.xhr ?? null;
	const tag          = container.tagName.toLowerCase();
	const pluginParams = {
		mirrorContainer: useContainer,
		revertOnSpill  : true
	};

	// Ist `control` ein Link und `uri` ist leer, dann hole URI aus dem Attribut `href`.
	if (tag === 'table') {
		const tbody = SelectorEngine.findOne('tbody', useContainer);

		if (tbody) {
			useContainer = tbody;
			childs       = SelectorEngine.children(useContainer, '*');
		}

		// Bei nur einem Kindelement ist eine Sortierung sinnlos.
		if (childs && childs.length > 1) {
			addSortableHandleTable(useContainer);

			pluginParams.mirrorContainer = useContainer;
			pluginParams.moves           = (el, container, handle) => {
				return handle.classList.contains('sortable-handle');
			};
		}
	} else {
		const sortableHandle = SelectorEngine.find('.sortable-handle', useContainer);

		if (sortableHandle.length > 0) {
			pluginParams.moves = (el, container, handle) => {
				return handle.classList.contains('sortable-handle');
			};
		}
	}

	// Bei nur einem Kindelement ist eine Sortierung sinnlos.
	if (childs && childs.length > 1) {
		dragula([useContainer], pluginParams)
			.on('drag', function (element) {
				Manipulator.removeClass(element, 'sortable-moved');
			})
			//.on('drop', function (element, target, source, sibling) {
			.on('drop', function (element, target) {
				const sorting     = getCurrentSorting(target);
				const eventSorted = EventHandler.trigger(container, `sorted${EVENT_KEY}`, {
					sortableData     : sorting,
					sortableContainer: container,
					sortableItem     : element
				});

				Manipulator.addClass(element, 'sortable-moved');

				if (form) {
					EventHandler.trigger(form, 'changeDetected', {
						changeDetectedOn : element
					});
				}

				if (window.axios && xhr) {
					axios.post(xhr, {
						sorting: sorting
					})
						.then(function (response) {
							if (response && response.status === true) {
								execute(eventSorted);
							}
						})
						.catch(function (error) {
							throw new Error(error);
						});
				} else {
					execute(eventSorted);
				}
			}).on('over', function (element, container) {
				Manipulator.addClass(container, 'sortable-over');
			}).on('out', function (element, container) {
				Manipulator.removeClass(container, 'sortable-over');
			});
	}
};

// ------
// Public
// ------

/**
 * ´Sort-, Filterlist´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`[data-${NAME}]`);

	if (collection.length) {
		if (window.dragula === undefined) {
			const msg = 'The dependency ´dragula.js´ cannot be loaded/initialized';

			if (window.Prins.error) {
				window.Prins.error.show({
					msg: msg
				});
			} else {
				throw new Error(msg);
			}
		} else {
			for (const element of collection) {
				const type    = Manipulator.getDataAttribute(element, NAME);
				const context = (type && ['single', 'connected'].contains(type)) ? type : 'single';

				if (context === 'single') {
					group.push(render(element));
				} else if(context === 'connected') {
					group.push(renderConnected(element));
				}
			}
		}
	}

	return group;
};

// Export
export default {
	init: init
};
