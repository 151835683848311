/**
 * Prins: Config
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../shared/utils/index';
import configClient from '../../client/config/config';

// -------
// Private
// -------

// Icons
const icons = {
	close         : '<i aria-hidden="true" class="far fa-times"></i>',
	danger        : '<i aria-hidden="true" class="fa-solid fa-triangle-exclamation"></i>',
	edit          : '<i aria-hidden="true" class="fa-regular fa-pen"></i>',
	info          : '<i aria-hidden="true" class="fa-regular fa-circle-info"></i>',
	move          : '<i aria-hidden="true" class="fa-solid fa-up-down-left-right"></i>',
	moveHorizontal: '<i aria-hidden="true" class="fa-solid fa-left-right"></i>',
	moveVertical  : '<i aria-hidden="true" class="fa-solid fa-up-down"></i>',
	next          : '<i aria-hidden="true" class="far fa-chevron-right"></i>',
	prev          : '<i aria-hidden="true" class="far fa-chevron-left"></i>',
	scrollTop     : '<i aria-hidden="true" class="far fa-arrow-up"></i>',
	success       : '<i aria-hidden="true" class="fa-regular fa-circle-check"></i>',
	warning       : '<i aria-hidden="true" class="fa-solid fa-hexagon-exclamation"></i>',
};

// ------
// Public
// ------

// Export
export default extend({}, {
	icons
}, configClient);
